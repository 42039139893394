<template>
    <EditableArea
        :value="instanceLongName"
        :apiURL="'/instances/' + this.$route.params.iid"
        successMessage="Instance name successfully updated!"
        editAttr="long_id"
        editAttrFullName="instance name"
        defaultValue="Instance name not available"
        color="white"
        :showEditIcon="isInstanceEditor && canDeleteInstanceOrEditFields"
        :id="parseInt($route.params.sid, 10)"
        :finally="
            () => {
                this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
            }
        "
    ></EditableArea>
</template>

<script>
import { mapGetters } from 'vuex'
const EditableArea = () => import('@/components/EditableArea')

export default {
    name: 'TheInstanceEditableNameField',
    components: {
        EditableArea
    },
    computed: {
        ...mapGetters('instanceStore', ['canDeleteInstanceOrEditFields', 'isInstanceEditor']),
        instanceLongName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        }
    }
}
</script>
